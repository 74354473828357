<template>
  <asom-card title="Validation Results">
    <div class="flex flex-col space-y-4" id="collection_summary_table">
      <asom-alert v-if="hasError" variant="error" :title="`${sortedErrorList.length} errors found`">
        <p class="text-sm">Please resolve all errors.</p>
      </asom-alert>
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" v-if="hasError">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-20 table-fixed">
              <caption class="hidden"></caption>
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    No
                  </th>
                  <th scope="col" class="w-1/2 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Error
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Value
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <template v-for="(item, index) in sortedErrorList" :key="index">
                  <tr>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{index + 1}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{item.error}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{item.cellContent}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{formatErrorLocation(item)}}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <asom-alert v-else variant="success" title="No Error Found">
        <p class="text-sm">You can proceed to upload the report.</p>
      </asom-alert>
      <asom-alert v-if="overwriteErrors.length" variant="warning" :title="`${overwriteErrors.length} overwrite records found`">
        <p class="text-sm">Please confirm all overwrite data is correct.</p>
      </asom-alert>
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" v-if="overwriteErrors.length">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-20 table-fixed">
              <caption class="hidden"></caption>
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    No
                  </th>
                  <th scope="col" class="w-1/2 px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Error
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Value
                  </th>
                  <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Location
                  </th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <template v-for="(item, index) in sortedWarningList" :key="index">
                  <tr>
                    <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {{index + 1}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{item.error}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{item.cellContent}}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{formatErrorLocation(item)}}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <slot></slot>
    </div>
    <template #footer v-if="!hasError">
      <div class="flex justify-end flex-1">
        <asom-button  
          text="Submit"
          :is-loading="isSubmitting"
          :disabled="isSubmitting"
          @click="onSubmit"  />
      </div>
    </template>
  </asom-card>
</template>
<script>
import get from 'lodash.get'
import sortBy from 'lodash.sortby'
import AsomAlert from '../../../../components/alert/AsomAlert.vue';

const ROW_ALPHABE = "0ABCDEFGHIJKLMNOPQRSTUVWXYZ"

export default {
  components: { AsomAlert },
  props: {
    errors: {
      type: Array,
      default: () => []
    },
    isSubmitting: {
      type: Boolean,
      default: false
    }
  },
  emits: [ 'submit' ],
  mounted() {
    this.$nextTick(function() {
      document.getElementById('collection_summary_table').scrollIntoView({block: 'start', behavior: 'smooth'});
    })
  },
  computed: {
    dataErrors() {
      return this.errors.filter(({ dataOverwrite }) => !dataOverwrite)
    },
    overwriteErrors() {
      return this.errors.filter(({ dataOverwrite }) => dataOverwrite)
    },
    hasError() {
      return this.dataErrors.length > 0;
    },
    sortedErrorList() {
      return sortBy(this.dataErrors, [ 'fileError', 'rowError', 'cellError', 'sheetIndex', 'rowIndex', 'cellIndex' ]);
    },
    sortedWarningList() {
      return sortBy(this.overwriteErrors, [ 'fileError', 'rowError', 'cellError', 'sheetIndex', 'rowIndex', 'cellIndex' ]);
    },
  },
  methods: {
    formatErrorLocation({
      rowError,
      cellError,
      rowIndex,
      cellIndex,
      sheetName
    }) {
      if (rowError) {
        return `Sheet "${sheetName}", row ${rowIndex}`;
      }
      if (cellError) {
        const column = get(ROW_ALPHABE, cellIndex, '');
        return `Sheet "${sheetName}", row ${rowIndex}, colunm ${column}`;
      }
      return '';
    },
    onSubmit() {
      this.$emit('submit');
    }
  }
}
</script>